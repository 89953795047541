<template>
  <div class="container">
    <el-form :inline="true">
      <el-form-item label="场馆" required>
        <venueSelect ref="ven" :venueid.sync="venue_id" ></venueSelect>
      </el-form-item>
      <el-form-item label="查询日期" required>
        <el-date-picker
          v-model="changeTime"
          type="date"
          value-format="yyyy-MM-dd"
          placeholder="选择日期"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="服务" >
        <serviceSelect
          :condition="{ venue_id }"
          :serviceid.sync="service_id"
          @change="changeService"
        ></serviceSelect>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="search"
          >查看预约表</el-button
        >
      </el-form-item>
      <el-form-item>
        <el-button type="warning" @click="toCreate"
            >创建场地排期</el-button
          >
      </el-form-item>
    </el-form>
    
    <div>
      <h3>场地可预约表</h3>
      <div v-if="!venue_id">请选择场馆</div>
      <div v-if="loadStatus == 'nomore'"><el-link :underline="false" @click="toCreate" type="primary">没有课程预约信息，去添加场馆设备预约排期吧</el-link></div>
      <appointmentTable :changeTime="changeTime" :setPeople="true" type="show" :datas="appointInfo"></appointmentTable>
      <h3>场地排期记录</h3>
      <el-form :inline="true">
        <el-form-item label="排课类型">
          <el-select v-model="type" placeholder="排期类型" clearable>
            <el-option
              v-for="(item, i) in typeOption"
              :label="item.label"
              :value="item.value"
              :key="i"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="">
          <el-button type="primary" @click="getList"
            >查询记录</el-button>
        </el-form-item>
      </el-form>

      <auto-table
        :DataList="DataList"
        :option="Option"
        :total="Total"
        @changePage="getList"
      >
        <template #day="{ row }">
          <div v-if="row.type == 'week_day'">周{{ row.week_day }}</div>
          <div v-if="row.type == 'time'">{{ row.start_time.split(" ")[0] }}至{{ row.end_time.split(" ")[0] }}</div>
        </template>
        <template #type="{ row }">
          <div v-if="row.type == 'week_day'">按星期排</div>
          <div v-if="row.type == 'time'">按时间段排</div>
        </template>
        <template #time="{ row }">
          <div>{{ row.start_time.split(" ")[0] }}至{{ row.end_time.split(" ")[0] }}</div>
        </template>
        <template #handler="{ row }">
          <el-button type="primary" size="small" @click="toedit(row)"
            >详情</el-button
          >
        </template>
      </auto-table>
    </div>
  </div>
</template>

<script>
import serviceSelect from "@/components/select/serviceSelect.vue";
import venueSelect from "@/components/select/venueSelect.vue";
import appointmentTable from "../components/appointmentTable.vue";
export default {
  components: {
    serviceSelect,
    venueSelect,
    appointmentTable,
  },
  data() {
    return {
      venue_id: "",
      service_id: "",
      appointInfo: [],
      changeTime: new Date().Format("yyyy-MM-dd"),
      typeOption: [
        {
          label: "按周几排",
          value: "week_day",
        },
        {
          label: "按时间段排",
          value: "time",
        },
      ],
      DataList: [],
      Option: [
        { name: "日期", value: "day", type: "custom" },
        { name: "生效时间", value: "time", type: "custom" },
        { name: "类型", value: "type", type: "custom" },
        { name: "操作", value: "handler", type: "custom" },
      ],
      Total: 0,
      type: "time",
      loadStatus:'loading'
    };
  },

  mounted() {
    if(this.$route.query.vid){
        this.venue_id = this.$route.query.vid
        this.getVenueInfo()
    }
  },

  methods: {
     getVenueInfo() {
      let url = "/user/mechanism/venue/findById";
      this.$axios({
        url,
        params: { id: this.venue_id },
      }).then((res) => {
        this.search();
        this.$refs.ven.setValue(res.data.data);
      });
    },
    toCreate(){
        this.$router.push({
          name:'serviceCreateAppointment',
          query:{
              vid:this.venue_id || null
          }
        })
    },
    toedit(row){
        this.$router.push({
          name:'serviceTemplateEdit',
          params:{
              type:row.type,
              start_time:row.start_time,
              end_time:row.end_time,
              venue_id:this.venue_id,
              week_day:row.week_day
          }
        })
    },
    search(){
      if(this.venue_id){
         this.getDayAppointment()
         this.getList(1)
      }else{
        this.$message("请选择场馆")
      }
     
    },
    //查询当天预约表
    getDayAppointment() {
      this.loadStatus = 'loading'
      this.$axios({
        url: "/user/serviceAppointTemp/queryDayAppointInfoAll",
        params: {
          venue_id:this.venue_id || '',
          service_id: this.service_id || null,
          start_time: this.changeTime + " 00:00:00",
          end_time: this.changeTime + " 23:59:59",
        },
      }).then((res) => {
         if(res.data.data.length == 0){
            this.loadStatus = 'nomore'
        }
        this.appointInfo = res.data.data;
       
        
      });
    },
    changeService(v) {},
    getList(v) {
      let page = v || 1;
      if (this.type == "week_day") {
        this.getWeekDayList(page);
      } else {
        this.getTimeList(page);
      }
    },
    //获取按星期排课列表
    getWeekDayList(v) {
      let page = v || 1;
      this.$axios({
        url: "/user/serviceAppointTemp/weekDayTypeList",
        params: {
          currentPage: page,
          pageSize: 10,
          status: 1,
          venue_id: this.venue_id,
        },
      }).then((res) => {
        this.DataList = res.data.data.rows;
        this.Total = res.data.data.total;
      });
    },
    //获取按时间排课列表
    getTimeList(v) {
      let page = v || 1;
      this.$axios({
        url: "/user/serviceAppointTemp/timeTypeList",
        params: {
          currentPage: page,
          pageSize: 10,
          status: 1,
          venue_id: this.venue_id,
        },
      }).then((res) => {
        this.DataList = res.data.data.rows;
        this.Total = res.data.data.total;
      });
    },
  },
};
</script>

<style lang="less" scoped>
h3{
  margin-bottom: 10px;
}
</style>